
    import { Vue } from "vue-class-component";
    
    export default class Terminal extends Vue {
        inputSize: number = 1;
        message: string = "";
        output: any = [];
    
        changeSize(event) {
            if (event.keyCode == 8) {
                if (this.message.length > 0) {
                    this.inputSize -= 8.5;
                }
            } else if (event.keyCode == 13) {
                this.handleCommand();
            } else {
                this.inputSize += 8.5;
            }
            return this.inputSize;
        }
    
        private reset(): void {
            this.message = "";
            this.inputSize = 1;
        }
    
        handleCommand() {
            switch (this.message.toLowerCase()) {
                case "help": {
                    this.output.push({
                        input: this.message,
                        response:
                            'Available Commands: ["<span class="color-hot">About</span>", "<span class="color-hot">Links</span>", <span class="color-hot">"Skills"</span>]',
                    });
                    break;
                }
                case "about": {
                    this.output.push({
                        input: this.message,
                        response:
                            "Hello, I'm Lemon and I'm a full stack developer!",
                    });
                    break;
                }
                case "github": {
                    this.output.push({
                        input: this.message,
                        response:
                            "<a class='color-hot' href='https://github.com/japandotorg'>Github</a>",
                    });
                    break;
                }
                case "skills": {
                    this.output.push({
                        input: this.message,
                        response: `<div><span class='color-hot'>Languages:</span> Python, Rust, Go, JavaScript, TypeScript, Ruby, SQL, Swift, Redis, Kotlin</div> <div><span class='color-hot'>Frameworks:</span> Django, Flask, FastAPI, Angular, React, VueJS, NextJS</div><div><span class='color-hot'>Technologies:</span> Git, GraphQL, Rest, OCR</div>`,
                    });
                    break;
                }
                default: {
                    this.output.push({
                        input: this.message,
                        response: "This command does not exist.",
                    });
                    break;
                }
            }
            this.reset();
        }
    
        autoFocus() {
            window.setTimeout(() => {
                (this.$refs.commandInput as any).focus();
            }, 0);
        }
    }
